import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Layout from "../../components/layout"
import { Disclosure } from "@headlessui/react"
import Markdown from "react-markdown"
import Footer from "../../components/footer"

export const query = graphql`
  query ServicesQuery($slug: String!) {
    strapiServices(slug: { eq: $slug }) {
      strapiId
      title
      slug
      purchase_flow
      sections {
        content
        description
        title
      }
      published_at(formatString: "DD MMMM, YYYY")
      product_type
      price
      externalLink
      duration
      description
      categories {
        slug
        title
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 493)
          }
        }
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiServices
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  }

  console.log(article, "art")

  return (
    <Layout seo={seo}>
      <div className="rounded-b-2xl lg:rounded-b-3xl shadow-xl">
        <div className="lg:mx-14 px-4 sm:px-6 py-12">
          <div className="flex flex-col gap-12 py-12">
            <div className="p8-12">
              <p className="text-7xl font-semibold">{article.title}</p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="inline-block lg:flex lg:justify-between ">
                <div className="lg:w-2/4">
                  <div className="flex gap-x-6 pb-4">
                    {article.categories.map((category, i) => (
                      <Link
                        to={`/service-categories/${category.slug}`}
                        className="px-6 py-1 rounded-full border-primary border"
                        style={{ fontWeight: "800" }}
                      >
                        {category.title}
                      </Link>
                    ))}
                  </div>
                  <div className="text-lg leading-5">
                    <Markdown
                      children={article.description}
                      escapeHtml={false}
                    />
                  </div>
                </div>
                <div className="lg:w-1/3 lg:pt-12 py-4">
                  <div className="flex flex-col gap-6 w-full font-bold">
                    <div
                      className="border-t flex flex-row justify-between border-primary rounded-l-full text-lg"
                      style={{ borderSpacing: "0" }}
                    >
                      <div
                        className="border-r border-t-0 border-l border-b rounded-full px-6 py-1 border-primary"
                        style={{ borderSpacing: "0" }}
                      >
                        <p>Price</p>
                      </div>
                      <p style={{ fontWeight: "normal" }}>{article.price}</p>
                    </div>
                    <div
                      className="border-t flex flex-row justify-between border-primary rounded-l-full text-lg"
                      style={{ borderSpacing: "0" }}
                    >
                      <div
                        className="border-r border-t-0 border-l border-b rounded-full px-6 py-1 border-primary"
                        style={{ borderSpacing: "0" }}
                      >
                        <p>Product Type</p>
                      </div>
                      <p style={{ fontWeight: "normal" }}>
                        {article.product_type}
                      </p>
                    </div>
                    <div className="border-t flex flex-row justify-between border-primary rounded-l-full text-lg">
                      <div className="border-r border-t-0 border-l border-b rounded-full px-6 py-1 border-primary">
                        <p>Duration / Dates</p>
                      </div>
                      <p style={{ fontWeight: "normal" }}>{article.duration}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex lg:justify-end py-12">
                <GatsbyImage
                  className="rounded-lg "
                  image={
                    article.image.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={`Hero image`}
                />
              </div>
            </div>
            <div className="divide-y-2 divide-black border-t-2 border-b-2 border-primary">
              {article.sections.map((section, i) => (
                <Disclosure as="div" className="py-8" key={i}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        as="div"
                        className="flex justify-between text-3xl"
                      >
                        <p className="font-bold">{section.title}</p>
                        {open ? <span>-</span> : <span>+</span>}
                      </Disclosure.Button>
                      <Disclosure.Panel
                        as="div"
                        className="w-2/3 text-lg leading-5 flex flex-col gap-4 my-6"
                      >
                        <Markdown
                          children={section.content}
                          escapeHtml={false}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className="flex justify-center">
              <div className="inline-block text-center space-y-4 lg:space-y-0  lg:flex gap-8 text-small py-16">
                <div className="mb-10">
                  <Link
                    to="/services"
                    className="px-8 py-2 space-x-2 text-white rounded-full hover:bg-white hover:text-navbar border-2 border-navbar bg-navbar "
                    style={{ boxShadow: "0px 2px 5px 3px rgba(0, 0, 0, 0.25)" }}
                  >
                    <span>See similar services</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline-block -rotate-45"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>{" "}
                <div className="mb-10">
                  {article.purchase_flow ? (
                    <Link
                      to="/interview-format"
                      className="px-8 py-2 space-x-2 text-white rounded-full shadow-xl"
                      style={{
                        backgroundColor: "#0091EA",
                        boxShadow: "0px 2px 5px 3px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <span>Purchase</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 inline-block"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  ) : (
                    <Link
                      to="/contact"
                      className="px-8 py-2 space-x-2 text-white rounded-full shadow-xl"
                      style={{
                        backgroundColor: "#0091EA",
                        boxShadow: "0px 2px 5px 3px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <span>Get in touch</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 inline-block"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Article
